import * as React from "react"
import DocumentationContent from "../../components/DocumentationContent"
import DocumentationSidebar from "../../components/DocumentationSidebar"
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../../components/Layout'
import Header from '../../components/Header'
import Content, { HTMLContent } from '../../components/Content'
import Documentation from '../../components/Documentation'
import DemoSidebar from '../../components/DemoSidebar'
import { CopyBlock, CodeBlock, atomOneDark, solarizedDark, xt256 } from "react-code-blocks";
import {Image, SlideshowLightbox} from "lightbox.js-react";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import RelatedContent from '../../components/RelatedContent'
import Footer from '../../components/Footer'

export default function ProductListingDemoComponent () {
  const images = [{title: "Photon", src: "https://source.unsplash.com/600x400/?pastel+cloud"}, 
  {title: "Inversion", src: "https://source.unsplash.com/500x400/?pastel+clouds"},
  {title: "Legion", src: "https://source.unsplash.com/600x400/?pastel+sky"},
  {title: "Inversion", src: "https://source.unsplash.com/600x400/?pastel"},
  {title: "Twenty Neue", src: "https://source.unsplash.com/600x400/?sky+pastel"},
  {title: "Geometrik", src: "https://source.unsplash.com/600x400/?dreamy+clouds"},         
  {title: "Achtung", src: "https://source.unsplash.com/600x400/?pastel+fruit"},];
  const showLineNumbers = true;
  const wrapLines = true;
  const codeBlock = false;

  const productsCafe = [
    {
      id: 1,
      name: 'Macarons',
      href: '#',
      imageSrc: 'https://images.pexels.com/photos/808941/pexels-photo-808941.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      imageAlt: "Macarons",
      price: '$6',
      color: 'Black',
    },
    {
        id: 2,
        name: 'Pancakes',
        href: '#',
        imageSrc: 'https://images.pexels.com/photos/2280545/pexels-photo-2280545.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
        imageAlt: "Pancakes",
        price: '$4',
        color: 'White',
      },
      {
        id: 3,
        name: 'Macaron Box',
        href: '#',
        imageSrc: 'https://images.pexels.com/photos/1346345/pexels-photo-1346345.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
        imageAlt: "Pink macarons.",
        price: '$7',
        color: 'Navy',
      },
      {
        id: 4,
        name: 'Berry Cake',
        href: '#',
        imageSrc: 'https://images.pexels.com/photos/6133337/pexels-photo-6133337.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
        imageAlt: "Raspberry cake.",
        price: '$4.20',
        color: 'White',
      },
      {
        id: 5,
        name: 'Very Berry Cake',
        href: '#',
        imageSrc: 'https://images.pexels.com/photos/461431/pexels-photo-461431.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
        imageAlt: "Cake with berries such as strawberries and raspberries.",
        price: '$7',
        color: 'Navy',
      },
      {
        id: 6,
        name: 'Strawberry Delight',
        href: '#',
        imageSrc: 'https://images.pexels.com/photos/4722014/pexels-photo-4722014.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
        imageAlt: "Strawberry cake",
        price: '$12',
        color: 'White',
      },
    ]

  const products = [
    {
      id: 1,
      name: 'Macarons',
      href: '#',
      imageSrc: 'https://source.unsplash.com/WbZesfqwR-A/1600x1600',
      imageAlt: "Macarons",
      price: '$6',
      color: 'Black',
    },
    {
        id: 2,
        name: 'Crepes',
        href: '#',
        imageSrc: 'https://source.unsplash.com/jsgJtBOR6jY/1600x1600',
        imageAlt: "Crepes",
        price: '$4',
        color: 'White',
      },
      {
        id: 3,
        name: 'Pink Macarons',
        href: '#',
        imageSrc: 'https://source.unsplash.com/UXkAjHqgWK0/1600x1600',
        imageAlt: "Pink macarons.",
        price: '$7',
        color: 'Navy',
      },
      {
        id: 4,
        name: 'Berry Cake',
        href: '#',
        imageSrc: 'https://source.unsplash.com/Fm5VRMqvy4M/1600x1600',
        imageAlt: "Raspberry cake.",
        price: '$4.20',
        color: 'White',
      },
      {
        id: 5,
        name: 'Very Berry Cake',
        href: '#',
        imageSrc: 'https://source.unsplash.com/s8HpMrQtl8E/1600x1600',
        imageAlt: "Cake with berries such as strawberries and raspberries.",
        price: '$7',
        color: 'Navy',
      },
      {
        id: 6,
        name: 'Strawberry Delight',
        href: '#',
        imageSrc: 'https://source.unsplash.com/hXqbSgOPjSI/1600x1600',
        imageAlt: "Strawberry cake",
        price: '$12',
        color: 'White',
      },
      {
        id: 7,
        name: 'Pastel Macarons',
        href: '#',
        imageSrc: 'https://source.unsplash.com/i5BY6W2ttts/1600x1600',
        imageAlt: "Pastel pink macarons stacked upon each other.",
        price: '$7.50',
        color: 'Navy',
      },
      {
        id: 8,
        name: 'Madeleines',
        href: '#',
        imageSrc: 'https://source.unsplash.com/TgJHwwTSZ2Y/1600x1600',
        imageAlt: "Madeleines in basket.",
        price: '$4.50',
        color: 'Navy',
      },
    ]

  return <Layout>
    <section className="section section--gradient font-inter bg_white">
      <Header overrideDarkTheme={true} />
    
        <div className="pt-4">
        <main className="flex-grow">

            <section>
            <div className="max-w-6xl mx-auto px-2 sm:px-2">
                <div className="pt-32 pb-12 md:pt-40 md:pb-20">

                {/* Main content */}
                <div className="md:flex md:justify-between" data-sticky-container>

                    <DemoSidebar />

                    <div className="md:flex-grow  font-inter">

                      {/* <Header /> */}
                      <div className="text-lg text-gray-600">
                        <h1 className="h1 text-gray-900 mb-4 font-bold">Choose your options below</h1>

                        <div className="bg-white">
                          <div className="max-w-2xl mx-auto pt-2 sm:pt-4 lg:max-w-7xl">
                            <h2 className="sr-only">Products</h2>

                            <div >
                              <SlideshowLightbox lightboxIdentifier="l1" theme="day" showThumbnails={true}
                              className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:gap-x-8">
                              {productsCafe.map((product) => (
                                <div key={product.id} className="group">
                                  <div className="h-48 aspect-w-1 aspect-h-1 bg-gray-200 rounded-lg overflow-hidden xl:aspect-w-7 xl:aspect-h-8">
                                    <img
                                      src={product.imageSrc}
                                      alt={product.imageAlt}
                                      data-lightboxjs="l1"
                                      className="h-full w-full object-cover group-hover:opacity-75 demoImg"
                                    />
                                  </div>
                                  <h3 className="mt-4 text-sm text-gray-700">{product.name}</h3>
                                  <p className="mt-1 text-lg font-medium text-gray-900">{product.price}</p>
                                </div>
                              ))}
                              </SlideshowLightbox>

                            </div>
                          </div>
                        </div>

      </div>

        <RelatedContent />
      {/* <Feedback /> */}

    </div>

                </div>

                </div>
            </div>
            </section>

            </main>
        </div>

    </section>

    <Footer overrideDarkTheme={true} />
    </Layout>
    
}