import React from 'react';

// import {ArrowButton, Checkbox, CheckboxStandard, Image, SwitchStandard, GlitchImageGallery, 
//     SwitchTick, Wave, UploadButton, ItemGallery, SlideshowGallery, LightboxGallery} from "interakt";
import { CopyBlock, CodeBlock, atomOneDark, solarizedDark, xt256 } from "react-code-blocks";
import {Link} from "gatsby"

function RelatedContent() {

  const codeString = '(num) => num + 1';
  const images = [{title: "Photon", src: "https://source.unsplash.com/600x400/?pastel+cloud"}, 
  {title: "Inversion", src: "https://source.unsplash.com/500x400/?pastel+clouds"},
  {title: "Legion", src: "https://source.unsplash.com/600x400/?pastel+sky"},
  {title: "Inversion", src: "https://source.unsplash.com/600x400/?pastel"},
  {title: "Twenty Neue", src: "https://source.unsplash.com/600x400/?sky+pastel"},
  {title: "Geometrik", src: "https://source.unsplash.com/600x400/?dreamy+clouds"},         
  {title: "Achtung", src: "https://source.unsplash.com/600x400/?pastel+fruit"},];
  const showLineNumbers = true;
  const wrapLines = true;
  const codeBlock = false;

  return (
    <div className="mt-8">
        <h3 className="h3 mb-8 text-extrabold">Related</h3>
        <a className="flex justify-between items-center p-4 rounded border border-gray-200
         transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="https://github.com/silvia-odwyer/lightbox.js">
          <div>
            <div className="text-normal font-medium mb-1">Open a feature request</div>
            <div className="text-sm text-gray-600">Got a feature request you'd like to be added? Be sure to open an issue in our 
            GitHub repository!</div>
          </div>
          <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
          </svg>
        </a>
        <Link className="flex justify-between items-center p-4 rounded border border-gray-200 transition 
        duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" to="/demo/">
          <div>
            <div className="text-normal font-medium mb-1">Demos</div>
            <div className="text-sm text-gray-600">Be sure to check out our range of Demos, which feature a wide variety of 
            use-cases and applications for Lightbox.js.</div>
          </div>
          <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
          </svg>
        </Link>
        <hr className="w-full h-px pt-px bg-gray-200 border-0 mt-8" />
      </div>
  );
}

export default RelatedContent;